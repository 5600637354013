<template>
  <div class="page">
    <div class="c-emailsent c-emailsent_h-800">
      <div class="c-emailsent__body c-emailsent__body_top-208">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WithdrawEmailSent',
  components: {
  },
  mounted:function(){
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    let id = searchParams.get('id')
    let code = searchParams.get('code')
    let typeConfirm = (searchParams.get('action')=="confirm"?1:0)

    this.$http.post(this.createApiUrlChestNut('wallets/withdrawal/' + (typeConfirm?("confirm/" + id):("cancel/" + id))),{
      "code": code
    }).then(() =>
    {
      if (typeConfirm) {
        this.$router.push('/emailsent-withdrawsuccess').catch(() => {})
      }
      else {
        this.$router.push('/emailsent-withdrawcancelled').catch(() => {})
      }
    },err => {
      console.log(err)
      this.$router.push('/emailsent-withdrawexpired').catch(() => {})
    })
  },
  data() {
    return {
    }
  },
}
</script>
